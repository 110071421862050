import axios, { getHeaders, ApiError } from './axiosCustom';
import { SignatureBase as _SignatureBase } from './admin/signature';
import { SignerBase as _SignerBase, Signer as _Signer } from './admin/signer';
// import { Doc as _Doc } from './admin/document';
import {
	DocClient,
	SignerClient,
	SignatureClient,
} from '@interfaces/client';


export const getDocument = (
	documentId: string,
	signerId: string,
	envelopeId: string,
): Promise<DocClient> => {
	return new Promise((resolve, reject) => {
		// const url = `/api/signer/${envelopeId}/${signerId}/${documentId}`;
		const url = `/api/admin/envelope/${envelopeId}/documents/${documentId}`;
		axios({
			method: 'get',
			url: url,
			headers: getHeaders(),
			// data: data
		}).then((response) => {
			resolve(response.data as DocClient);
		}).catch((error) => {
			reject(error);
		});
	});
}

//TODO need this endpoint
export const updateSignerName = (
	envelopeId: string,
	signerId: string,
	fullName: string
) => {
	return new Promise<SignerClient>((resolve, reject) => {
		// const url = `/api/signer/envelope/${envelopeId}/signers/${signerId}?name=${fullName}`;
		const url = `/api/admin/envelope/${envelopeId}/signers/${signerId}?fullName=${fullName}`;
		axios({
			method: 'put',
			url: url,
			headers: getHeaders(),
			// data: data
		}).then((response) => {
			resolve(response.data as SignerClient);
		}).catch((error) => {
			reject(error);
		});
	});
}

export const getSigner = (
	envelopeId: string,
	signerId: string
) => {
	return new Promise<SignerClient>((resolve, reject) => {
		// const url = `/api/signer/envelope/${envelopeId}/signers/${signerId}`;
		const url = `/api/admin/envelope/${envelopeId}/signers/${signerId}`;
		axios({
			method: 'get',
			url: url,
			headers: getHeaders(),
			// data: data
		}).then((response) => {
			resolve(response.data as SignerClient);
		}).catch((error) => {
			reject(error);
		});
	});
}

export const createSignature = (
	document: File,
	envelopeId: string,
	signerId: string,
	documentId: string,
	signatureFieldId: string
) => {
	return new Promise<SignatureClient>((resolve, reject) => {
		const url = `/api/signer/sign/${envelopeId}/${signerId}/${documentId}/${signatureFieldId}`;
		// const url = `/api/admin/envelope/${envelopeId}/signers/${signerId}/sign/${documentId}/${signatureFieldId}`;

		const data = new FormData();
		data.append('document', document);

		axios({
			method: 'post',
			url: url,
			headers: getHeaders(),
			data: data
		}).then((response) => {
			resolve(response.data as SignatureClient);
		}).catch((error) => {
			reject(error);
		});
	});
}

// TODO need this endpoint
export const downloadSignature = (
	envelopeId: string,
	signerId: string,
	signatureId: string
): Promise<File> => {
	// const url = `/api/signer/envelope/${envelopeId}/signers/${signerId}/signature/${signatureId}/view`;
	const url = `/api/admin/envelope/${envelopeId}/signers/${signerId}/signature/${signatureId}/view`;
	console.log('Download Signature Url', url);
	return new Promise((resolve, reject) => {
		axios({
			url: url,
			method: 'GET',
			responseType: 'blob',
		}).then((response) => {
			const urlSplit = url.split('/')
			const fileName = urlSplit[urlSplit.length - 1];
			const file = new File([response.data as BlobPart], fileName);
			resolve(file);
		}).catch((error) => {
			reject(error);
		});
	});
};

// download view urls

export const getPDFForSigningUrl = (
	envelopeId: string,
	documentId: string,
	signerId: string,
) => {
	// this is the url that exists without any signatures
	return `/api/signer/${envelopeId}/${signerId}/${documentId}`;
}

export const getPDFViewUrl = (
	envelopeId: string,
	documentId: string,
	signerId: string,
) => {
	return `/api/signer/${envelopeId}/${signerId}/${documentId}/view`;
}

export const getPDFDownloadUrl = (
	envelopeId: string,
	documentId: string,
	signerId: string,
) => {
	return `/api/signer/${envelopeId}/${signerId}/${documentId}/download`;
}