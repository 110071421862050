import React from 'react';
import style from "@components/DocumentRender/DocumentRender.module.scss";
import {TiArrowBack} from "react-icons/ti";
import {Signature} from "@interfaces/SignatureBoxes";
import {useParams} from "react-router-dom";

type Props = {
	sig: Signature,
	page: number,
	zoom: number,
	signSignatureBox: (signatureBoxId: string) => Promise<void> | void,
}

type Params = {
	documentId: string,
	envelopeId: string,
	signerId: string
}

const scalar = 1.3;
const xOffset = 3;

const SignatureBox = ((props: Props) => {
	const {zoom, sig, page, signSignatureBox} = props
	const {envelopeId, signerId} = useParams() as Params;
	const url = `/api/admin/envelope/${envelopeId}/signers/${signerId}/signature/${sig.signatureId}/view`;

	if (sig.page !== page || !sig?.box) {
		return null
	}

	if (sig.isAlreadyRendered) {
		return null;
	}

	if (sig.signatureId !== null) {

		return (
			<div
				className={style.signatureImage}
				style={{
					top: (sig.box.y - sig.box.height + xOffset) * zoom,
					left: sig.box.x * zoom,
					height: (sig.box.height * scalar) * zoom,
					width: (sig.box.width * scalar) * zoom,
				}}
			>
				<img alt={'signature-image'} src={url} style={{height: "100%", width: "100%"}}/>
			</div>
		)
	}

	return (
		<div
			className={style.signatureBox}
			style={{
				top: (sig.box.y - sig.box.height) * zoom,
				left: sig.box.x * zoom,
				height: sig.box.height * zoom,
				lineHeight: `${sig.box.height * zoom}px`,
				fontSize: `${zoom}rem`
			}}
			onClick={async () => {
				signSignatureBox(sig.signatureBoxId)
			}}
			id={sig.id}
		>
			<span className={style.sigIcon}>
				<TiArrowBack style={{transform: 'rotate(-90deg)'}}/>
			</span> <span className={style.sigText}>Sign Here</span>
		</div>
	);
})

export default SignatureBox;

