import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { createGlobalState } from 'react-global-hooks';
import Terms from '@components/Terms';

export const showTermsModalState = createGlobalState(false);

const TermsModal = () => {
	const [show, setShow] = showTermsModalState.use();

	const handleClose = () => {
		setShow(false);
	}
	return (
		<Modal
			show={show}
			onHide={handleClose}
		>
			<Modal.Header closeButton>
				<Modal.Title>Terms</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Terms />
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default TermsModal;