import axios, { AxiosRequestHeaders } from 'axios';
const axiosCustom = axios.create();
// put custom interceptors here
// instance.interceptors.request.use(function () {/*...*/});
export default axiosCustom;

// used later if needed
export const getHeaders = (): AxiosRequestHeaders => {
	return {};
}

export interface ApiError {
	message: string,
	error: number,
}