import React, { useEffect, useState } from 'react';
import createDemoDoc from '@api/createDemoDoc';
import { useHistory } from 'react-router-dom';

const Home = () => {
	const history = useHistory();
	const isDev = process.env.NODE_ENV === 'development';
	const [isLoading, setIsLoading] = useState(false);

	const makeDoc = (numSigners: number = 1) => {
		if (!isLoading) {
			setIsLoading(true);
			createDemoDoc('test@test.com', numSigners).then((data) => {
				console.log('data', data);
				setIsLoading(false);
				//@ts-ignore
				if (data.signer) {
					//@ts-ignore
					history.push(`/client/sign/${data.envelope.id}/${data.doc.id}/${data.signer.id}`);
				} else {
					//@ts-ignore
					history.push(`/client/sign/${data.envelope.id}/${data.doc.id}/${data.signer1.id}`);
					// do second signer in new tab
					//@ts-ignore
					window.open(`/client/sign/${data.envelope.id}/${data.doc.id}/${data.signer2.id}`, '_blank');
				}
			})
		}
	}
	// redirect if not development
	useEffect(() => {
		if (!isDev) {
			window.location.replace('https://hornet.capital');
		}
	}, [])

	if (!isDev) {
		return (
			<></>
		)
	}

	return (<>
		<div
			className='container'
			style={{
				marginTop: 30
			}}
		>
			<div className='card'>
				<div className='card-body'>
					<h3>Select Doc to make</h3>
					<div className='row'>
						<div className='col-6 text-center'>
							<button
								className='btn btn-primary'
								onClick={() => {
									makeDoc(1)
								}}
								disabled={isLoading}
							>
								{isLoading ? 'Generating...' : 'Generate Single Doc'}
							</button>
						</div>
						<div className='col-6 text-center'>
							<button
								className='btn btn-primary'
								onClick={() => {
									makeDoc(2)
								}}
								disabled={isLoading}
							>
								{isLoading ? 'Generating...' : 'Generate Dual Doc'}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</>);
}

export default Home;