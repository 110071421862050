import React from "react";
import {BrowserRouter as Router, Route, Switch,} from "react-router-dom";
import Home from '@pages/Home';
import TermsModal from '@components/TermsModal';
import DocumentRender from '@components/DocumentRender';
import SignerPage from '@pages/SignerPage';
import Doc from '@pages/Doc';
import RequireDocState from "@components/RequireDocState";
import TermsPage from "@pages/TermsPage";
import SigTest from '@pages/SigTest';


export default function App() {
	return (<>
		<Router>
			<Switch>
				<Route path="/client/sign/:envelopeId/:documentId/:signerId" exact><SignerPage/></Route>
				<Route path="/client/doc-sign/:envelopeId/:documentId/:signerId" exact>
					<RequireDocState><DocumentRender/></RequireDocState>
				</Route>
				<Route path="/client/doc/:envelopeId/:documentId/:signerId" exact>
					<Doc/>
				</Route>
				<Route path="/client/terms"><TermsPage/></Route>
				<Route path="/client/sig"><SigTest/></Route>
				<Route path="/"><Home/></Route>
			</Switch>
		</Router>
		<TermsModal/>
	</>);
}
