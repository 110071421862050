import React, { useState, useRef, useEffect, useMemo } from 'react';
import fontLoader, { fontObj, FontNames, fontScale } from '@utils/fontLoader';
import { signaturePickerState, signerState } from '@state/documentStates';
import { getInitials } from '@utils/basic';
import style from './SignaturePicker.module.scss';

type Errors = {
	[field: string]: string
}

type Props = {
	errors: Errors,
	updateErrors: () => void
}

const SignaturePicker = ({
	errors,
	updateErrors
}: Props) => {
	const [signaturePicker, setSignaturePicker] = signaturePickerState.use();
	const [isFontsLoaded, setIsFontsLoaded] = useState(false);
	const signer = signerState.useValue();
	const firstFieldRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		fontLoader.loadAllFonts().then(() => {
			// setIsFontsLoaded(true);
		});

		// focus on first field
		setTimeout(() => {
			if (firstFieldRef.current) {
				firstFieldRef.current.focus();
			}
		}, 300)
	}, []);

	useEffect(() => {
		if (signer?.fullName && signer.fullName.length > 2) {
			setSignaturePicker({
				fullName: signer.fullName,
				initials: getInitials(signer.fullName),
				fontName: signaturePicker.fontName
			})
		}
	}, [signer]);

	const fontTable = useMemo(() => {
		const fontSize = 24;

		return Object.keys(fontObj).map((font, index) => {
			// let className = signaturePicker.fontName === font ? style.fontRowSelected : style.fontRow;
			return (
				<tr
					className={signaturePicker.fontName === font ? style.fontRowSelected : style.fontRow}
					key={index}
					onClick={() => {
						const newState = { ...signaturePicker };
						newState.fontName = font as FontNames;
						setSignaturePicker(newState);
					}}
				>
					<td className={style.fontCell}>
						{/* <SignatureCanvas name={signaturePicker.fullName} fontName={font as FontNames} /> */}
						<span
							style={{
								//@ts-ignore
								fontSize: Math.round(fontSize * fontScale[font]),
								fontFamily: font
							}}
						>
							{signaturePicker.fullName}
						</span>
					</td>
					<td className={style.fontCell}>
						{/* <SignatureCanvas name={signaturePicker.initials} fontName={font as FontNames} /> */}
						<span
							style={{
								//@ts-ignore
								fontSize: Math.round(fontSize * fontScale[font]),
								fontFamily: font
							}}
						>
							{signaturePicker.initials}
						</span>
					</td>
				</tr>
			)
		})
	}, [signaturePicker, isFontsLoaded]);

	const nameError = 'name' in errors ? errors['name'] : null;
	const initialError = 'initials' in errors ? errors['initials'] : null;

	return (
		<>
			<div className='row'>
				<div className='col-8'>
					<div className="form-group">
						<label className={`${nameError ? 'text-danger' : ''}`}>Full Name</label>
						<input
							type="text"
							className={`form-control${nameError ? ' is-invalid' : ''}`}
							placeholder="John Doe"
							value={signaturePicker.fullName}
							ref={firstFieldRef}
							onChange={(e) => {
								const newState = { ...signaturePicker };
								newState.fullName = e.target.value.slice(0, 40);
								setSignaturePicker(newState);
								updateErrors();
							}}
						/>
						{
							nameError ?
								<small className="text-danger">
									{nameError}
								</small>
								:
								null
						}
					</div>
				</div>
				<div className='col-4'>
					<div className="form-group">
						<label className={`${initialError ? 'text-danger' : ''}`}>Initials</label>
						<input
							type="text"
							className={`form-control${initialError ? ' is-invalid' : ''}`}
							placeholder="JD"
							value={signaturePicker.initials}
							onChange={(e) => {
								const newState = { ...signaturePicker };
								// remove spaces limit to 4 chars
								newState.initials = e.target.value.split(' ').join('').slice(0, 4);
								setSignaturePicker(newState);
								updateErrors();
							}}
						/>
						{
							initialError ?
								<small className="text-danger">
									{initialError}
								</small>
								:
								null
						}
					</div>
				</div>
			</div>
			<label>Select Signature</label>
			<div className={style.pickerBox}>
				<table className="table">
					<thead className="thead-dark">
						<tr>
							<th scope="col">Full Name</th>
							<th scope="col">Initial</th>
						</tr>
					</thead>
					<tbody>
						{fontTable}

					</tbody>
				</table>
			</div>
		</>
	)
};

export default SignaturePicker;
