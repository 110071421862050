import axios from 'axios';

const download = (
	url: string
): Promise<File> => {
	return new Promise((resolve, reject) => {
		axios({
			url: url,
			method: 'GET',
			responseType: 'blob',
		}).then((response) => {
			const urlSplit = url.split('/')
			const fileName = urlSplit[urlSplit.length - 1];
			const file = new File([response.data as BlobPart], fileName);
			resolve(file);
		}).catch((error) => {
			reject(error);
		});
	});
};

export default download;