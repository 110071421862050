import React from 'react';
import Terms from '@components/Terms';

const TermsPage = () => {
	return (
		<div className='container' style={{ marginTop: 30 }}>
			<div className='card'>
				<div className='card-body'>
					<h3>Terms</h3>
					<Terms />
				</div>
			</div>
		</div>
	)
}

export default TermsPage;