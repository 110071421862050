import {createGlobalState} from 'react-global-hooks';
import {getDocument, getSigner} from '@api/client';
import {DocClient, SignerClient} from '@interfaces/client';
import SignaturePicked from '@interfaces/SignaturePicked';
import SignatureBoxes, {Signature} from '@interfaces/SignatureBoxes';
import {fontObj} from '@utils/fontLoader';

export const documentState = createGlobalState(null as DocClient | null);
export const signerState = createGlobalState(null as SignerClient | null);
export const zoomState = createGlobalState(1);

export const getSignatureBox = (
	id: string,
	x: number,
	y: number,
	page: number,
	signatureBoxId: string,
	signatureId: string | null = null
): Signature => {
	return {
		id: id,
		page: page,
		signatureId: signatureId,
		signatureBoxId: signatureBoxId,
		box: {
			x: Math.round(x),
			y: Math.round(y),
			width: 100,
			height: 30
		}
	}
}
export const signatureBoxesState = createGlobalState(null as SignatureBoxes | null);

export const signaturePickerState = createGlobalState({
	fullName: '',
	initials: '',
	fontName: Object.keys(fontObj)[0] // select first one by default
} as SignaturePicked);

export const updateDocumentStates = (
	envelopeId: string,
	documentId: string,
	signerId: string
) => {
	return new Promise((resolve, reject) => {
		const documentPromise = getDocument(documentId, signerId, envelopeId);
		const signerPromise = getSigner(envelopeId, signerId);

		Promise.all([documentPromise, signerPromise]).then((resArr) => {
			const [document, signer] = resArr;
			console.log({
				document: document,
				signer: signer
			})
			documentState.set(document as DocClient);
			signerState.set(signer as SignerClient);
			resolve(true);
		}).catch((err) => {
			reject(err);
		})
	})
}