
export const fontObj = {
	'DancingScript': 'DancingScript-Regular.ttf',
	'HerrVonMuellerhoff': 'HerrVonMuellerhoff-Regular.ttf',
	'HomemadeApple': 'HomemadeApple-Regular.ttf',
	'Kristi': 'Kristi-Regular.ttf',
	'Parisienne': 'Parisienne-Regular.ttf',
	'Satisfy': 'Satisfy-Regular.ttf',
	'Yellowtail': 'Yellowtail-Regular.ttf',
	'Sacramento': 'Sacramento-Regular.ttf',
	'MeowScript': 'MeowScript-Regular.ttf',
	'CedarvilleCursive': 'CedarvilleCursive-Regular.ttf'
}

export const fontScale = {
	'DancingScript': 1.1,
	'HerrVonMuellerhoff': 1.1,
	'HomemadeApple': 0.8,
	'Kristi': 1.3,
	'Parisienne': 1,
	'Satisfy': 1,
	'Yellowtail': 1,
	'Sacramento': 1,
	'MeowScript': 1,
	'CedarvilleCursive': 0.9,
}

export type FontNames = keyof typeof fontObj;

type LoadedFontObj = {
	[fontName: string]: FontFace
}

// Loads fonts for use with canvas
class FontLoader {
	private _loadedFonts = {} as LoadedFontObj;

	public loadAllFonts = async () => {
		let promiseArr = [];
		for (let key in fontObj) {
			promiseArr.push(this.loadFont(key as FontNames))
		}
		await Promise.all(promiseArr);
		return;
	}

	public loadFont = async (
		name: FontNames
	) => {
		if (!Object.keys(this._loadedFonts).includes(name)) {
			//@ts-ignore
			const url = `/fonts/${name === 'RobotoMono-Regular' ? 'RobotoMono-Regular.ttf' : fontObj[name]}`;
			// load the font
			const newFontFace = new FontFace(name, `url(${url})`);
			const newFont = await newFontFace.load();
			this._loadedFonts[name] = newFont;
			document.fonts.add(newFont);
		}

		return this._loadedFonts[name];
	}
}

let fontLoader = new FontLoader();

export default fontLoader;