import React from 'react';

const Terms = () => {
	return (
		<div>
			<p>
				Ullamco dolor duis occaecat cillum nulla aliqua Lorem non anim consectetur nulla. Sint dolore id proident occaecat 
				et eiusmod. Fugiat cupidatat sunt fugiat aute ea cupidatat exercitation officia officia adipisicing aliqua.
			</p>
			<p>
				Ullamco dolor duis occaecat cillum nulla aliqua Lorem non anim consectetur nulla. Sint dolore id proident occaecat 
				et eiusmod. Fugiat cupidatat sunt fugiat aute ea cupidatat exercitation officia officia adipisicing aliqua.
			</p>
			<p>
				Ullamco dolor duis occaecat cillum nulla aliqua Lorem non anim consectetur nulla. Sint dolore id proident occaecat 
				et eiusmod. Fugiat cupidatat sunt fugiat aute ea cupidatat exercitation officia officia adipisicing aliqua.
			</p>
		</div>
	)
}

export default Terms;