import React, { useState, useEffect, useRef } from 'react';
import { signNameOnCanvas } from '@utils/canvas';
import { fontObj, FontNames } from '@utils/fontLoader';
import { Redirect } from 'react-router-dom';

const SigTest = () => {
	const [fullName, setFullName] = useState('Jaret Burkett');
	const [sigBoxId, setSigBoxId] = useState('ff8081817f563ab1017f56fb45c60006');
	const isDev = process.env.NODE_ENV === 'development';
	const [selectedFont, setSelectedFont] = useState(Object.keys(fontObj)[0] as FontNames);
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const canvasTempRef = useRef<HTMLCanvasElement>(null);

	const updateSignature = () => {
		if (canvasRef.current && canvasTempRef.current) {
			signNameOnCanvas(
				fullName,
				selectedFont,
				canvasRef.current,
				canvasTempRef.current,
				sigBoxId
			);
		}
	};

	useEffect(() => {
		updateSignature();
	}, [fullName, selectedFont, sigBoxId])

	useEffect(() => {
		setTimeout(() => {
			updateSignature();
		}, 100)
	}, []);

	if (!isDev) {
		return (<Redirect to={'/'} />);
	}
	return (
		<div className='container' style={{ marginTop: 30 }}>
			<div className='card'>
				<div className='card-body'>
					<h3>Sig Tester</h3>

					<div className="form-group">
						<label htmlFor="fullNameField">Full Name</label>
						<input
							type="text"
							className="form-control"
							id="fullNameField"
							placeholder="John Doe"
							value={fullName}
							onChange={(e) => {
								setFullName(e.target.value);
							}}
						/>
					</div>

					<div className="form-group">
						<label htmlFor="sigBoxIdField">Sig Box Id</label>
						<input
							type="text"
							className="form-control"
							id="sigBoxIdField"
							placeholder="ff8081817f563ab1017f56fb45c60006"
							value={sigBoxId}
							onChange={(e) => {
								setSigBoxId(e.target.value);
							}}
						/>
					</div>

					<div className="form-group">
						<label htmlFor="selectFontField">Select Font</label>
						<select
							className="form-control"
							id="selectFontField"
							value={selectedFont}
							onChange={(e) => {
								setSelectedFont(e.target.value as FontNames);
							}}
						>
							{
								Object.keys(fontObj).map((key, index) => {
									return (
										<option key={index} value={key}>{key}</option>
									)
								})
							}
						</select>
					</div>

					<div>
						<canvas
							width={10}
							height={80}
							style={{
								background: '#fff',
								boxShadow: '0 1px 5px rgba(0,0,0,0.2)'
							}}
							ref={canvasRef}
						/>
						<canvas
							width={10}
							height={80}
							style={{
								display: 'none'
							}}
							ref={canvasTempRef}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SigTest;