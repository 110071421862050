import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FcDocument } from 'react-icons/fc';
import { AiOutlineLoading } from 'react-icons/ai';
import {
	getSigner,
	getDocument,
	getPDFViewUrl,
	getPDFDownloadUrl,
} from '@api/client';
import {
	DocClient,
	SignerClient,
} from '@interfaces/client';
import { getNumSignaturesLeft } from '@utils/doc';
import { Link } from 'react-router-dom';
import style from './Doc.module.scss';
import sendIframeMessage from '@utils/sendIframeMessage';

type Params = {
	documentId: string,
	envelopeId: string,
	signerId: string
}

const Done = () => {
	const { envelopeId, documentId, signerId } = useParams() as Params;
	const [isLoading, setIsLoading] = useState(true);
	const [message, setMessage] = useState(null as null | JSX.Element);
	const [doc, setDoc] = useState(null as null | DocClient);
	const [signer, setSigner] = useState(null as null | SignerClient);
	const [isDocComplete, setIsDocComplete] = useState(false);
	console.log('doc', doc)
	console.log('signer', signer)

	useEffect(() => {
		setIsLoading(true);
		getDocument(documentId, signerId, envelopeId).then((d) => {
			console.log('doc', doc);
			setDoc(d);
			if (signerId) {
				// get the signer id and display signer info
				getSigner(envelopeId, signerId).then((s) => {
					setSigner(s);
					setIsLoading(false);
					const numSignaturesLeft = getNumSignaturesLeft(d, s);
					console.log('numSignaturesLeft', numSignaturesLeft);
					setIsDocComplete(getNumSignaturesLeft(d) === 0);
					if (numSignaturesLeft === 0) {
						setMessage(<>You have successfully completed this document.</>)
						sendIframeMessage({
							stage: 4
						});
					} else {
						setMessage(<>You still need to finish signing this document. <Link to={
							`/client/sign/${envelopeId}/${documentId}/${signerId}`
						}>Click here to finish signing the document</Link></>)
					}
					// set message

				})
			} else {
				// done loading
				setIsLoading(false);
			}
		});
		sendIframeMessage({
			stage: 3
		});
	}, []);

	return (
		<div className='container' style={{ marginTop: 30 }}>
			<div className='card'>
				<div className='card-body'>
					<h3>
						{
							isLoading ? <><AiOutlineLoading className='spin' /> Loading</> : doc ? doc.filename : 'Not Found'
						}
					</h3>
					{
						isDocComplete ?
							<div className={style.docComplete}>
								Document Complete
							</div>
							:
							<div className={style.docNotComplete}>
								Waiting on signatures
							</div>
					}
					{message ? <div className='text-center'>{message} </div> : null}
					<div className={style.dlBox}>
						<div className='row'>
							<div className='col-6'>
								<div className={style.left}>
									<FcDocument />
								</div>
							</div>
							<div className='col-6' style={{ position: 'relative' }}>
								<div className={style.right} style={{
									opacity: isDocComplete ? 1 : 0.5
								}}>
									<a
										href={getPDFDownloadUrl(envelopeId, documentId, signerId)}
										className={`btn btn-primary btn-block${isDocComplete ? '' : ' disabled'}`}
										download={true}
									>
										Download
									</a>

									<a
										href={getPDFViewUrl(envelopeId, documentId, signerId)}
										className={`btn btn-primary btn-block${isDocComplete ? '' : ' disabled'}`}
										target="_blank"
									>
										View
									</a>
								</div>
							</div>
						</div>
					</div>
					<div
						className='text-center'
					>
						<button
							className='btn btn-success'
							style={{
								minWidth: 160
							}}
							onClick={() => {
								sendIframeMessage({
									action: 'DONE'
								});
							}}
						>
							Done
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Done;