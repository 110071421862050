import React, {useEffect, useMemo, useRef, useState} from 'react';
import {PDFDocument} from 'pdf-lib'; // https://www.npmjs.com/package/pdf-lib
import download from '@api/download';
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack'; // https://www.npmjs.com/package/react-pdf
import {range} from '@utils/basic';
import {getSignaturePng} from '@utils/canvas';
import {createSignature, getDocument, getPDFForSigningUrl,} from '@api/client';
import {getSignatureBoxes} from '@utils/doc';
import {AiOutlineLoading} from 'react-icons/ai';
import classnames from 'classnames';
import {documentState, signatureBoxesState, signerState, zoomState,} from '@state/documentStates';
import sendIframeMessage from '@utils/sendIframeMessage';

import {useHistory, useParams} from 'react-router-dom';

import style from './DocumentRender.module.scss';
import SignatureBox from "@components/DocumentRender/SignatureBox";
import ZoomButtons from "@components/DocumentRender/ZoomButtons";
import Button from "react-bootstrap/Button";
import SignatureBoxes from "@interfaces/SignatureBoxes";

type Params = {
	documentId: string,
	envelopeId: string,
	signerId: string
}

type PageSize = {
	width: number,
	height: number
}

const DocumentRender = () => {
	const {envelopeId, documentId, signerId} = useParams() as Params;
	const zoom = zoomState.useValue();
	const [numPages, setNumPages] = useState(null as null | number);
	const [displayFile, setDisplayFile] = useState(null as null | File);
	const docCanvasRef = useRef<HTMLCanvasElement>(null);
	const [isLoading, setIsLoading] = useState(false);
	const renderedSignatures = useRef([] as string[]);
	const [currentSignIndex, setCurrentSignIndex] = useState<number>(-1);
	const history = useHistory();
	const [scrollState, setScrollState] = useState(0);

	const [pageSizes, setPageSizes] = useState(null as null | PageSize[]);

	const [signatureBoxes, setSignatureBoxes] = signatureBoxesState.use();
	const signer = signerState.useValue();
	const [doc, setDocument] = documentState.use();

	const canSubmit = useMemo(() => {
		if (signatureBoxes) {
			try {
				const anyIncomplete = signatureBoxes?.some(x => x.signatureId === null);
				return !anyIncomplete;
			} catch (e) {
				console.log(e);
				console.log('signatureBoxes error', signatureBoxes)
				return false;
			}
		} else {
			return false;
		}
	}, [signatureBoxes]);

	useEffect(() => {
		if (signatureBoxes && signatureBoxes.length > 0 && currentSignIndex >= 0) {
			const element = document.getElementById(signatureBoxes[currentSignIndex].id);
			if (element) {
				element.scrollIntoView({behavior: 'smooth', block: 'center'});
			}
		}
	}, [currentSignIndex, signatureBoxes, scrollState]);

	const handlePrevious = () => {
		if (!signatureBoxes) return
		let prevNonSignedIndex = signatureBoxes.slice(0, currentSignIndex).reverse().findIndex(pos => !pos.signatureId);
		if (prevNonSignedIndex === -1) {
			prevNonSignedIndex = signatureBoxes.slice().reverse().findIndex(pos => !pos.signatureId);
			if (prevNonSignedIndex !== -1) {
				prevNonSignedIndex = signatureBoxes.length - 1 - prevNonSignedIndex;
			}
		} else {
			prevNonSignedIndex = currentSignIndex - prevNonSignedIndex - 1;
		}
		if (prevNonSignedIndex !== -1) {
			setScrollState(Date.now());
			setCurrentSignIndex(prevNonSignedIndex);
		}
	};

	const findAndSetNext = () => {
		handleNext(signatureBoxes);
	}

	const handleNext = (signatureBoxes: SignatureBoxes | null) => {
		if (!signatureBoxes) return
		let nextNonSignedIndex = signatureBoxes.findIndex((pos, i) => i > currentSignIndex && !pos.signatureId);
		if (nextNonSignedIndex === -1) {
			nextNonSignedIndex = signatureBoxes.findIndex(pos => !pos.signatureId);
		}
		if (nextNonSignedIndex !== -1) {
			setScrollState(Date.now());
			setCurrentSignIndex(nextNonSignedIndex);
		}
	};

	useEffect(() => {
		setIsLoading(true);
		// download the unsigned document (we generate signatures on frontend for viewing)
		download(
			getPDFForSigningUrl(envelopeId, documentId, signerId)
		).then(async (file) => {
			const pdfInBytes = await file.arrayBuffer();

			const pdf = await PDFDocument.load(pdfInBytes);

			// Process all the page sizes
			const pages = pdf.getPages();
			let sizes: PageSize[] = [];
			for (let i = 0; i < pages.length; i++) {
				const page = pages[i];
				sizes.push({
					width: page.getWidth(),
					height: page.getHeight()
				})
			}
			setPageSizes(sizes);
			setNumPages(pages.length);
			const fileOut = new File([pdfInBytes], doc?.filename || 'Unknown.pdf');
			setDisplayFile(fileOut);

			setIsLoading(false);
			await refreshDocumentData(true);
		})
		sendIframeMessage({
			stage: 2
		})
	}, []);

	const refreshDocumentData = async (isFirst = false) => {
		setIsLoading(true);
		const d = await getDocument(documentId, signerId, envelopeId);
		let newSignatureBoxes = getSignatureBoxes(
			//@ts-ignore
			signer,
			d
		);

		// if is first time, and signature boxes  with a signature id are already rendered.
		newSignatureBoxes = newSignatureBoxes.map(x => {
			if (isFirst && x.signatureId) {
				renderedSignatures.current.push(`${x.signatureId}`);
				return {
					...x,
					isAlreadyRendered: true
				}
			} else if (renderedSignatures.current.includes(`${x.signatureId}`)) {
				// prevents it from being rendered on other attempts
				return {
					...x,
					isAlreadyRendered: true
				}
			} else {
				return {
					...x,
					isAlreadyRendered: false
				};
			}
		});
		setSignatureBoxes(newSignatureBoxes);
		setDocument(d);
		setIsLoading(false);
		if (!isFirst) {
			handleNext(newSignatureBoxes);
		}
	}

	const signSignatureBox = async (signatureBoxId: string) => {
		const signedIndex = signatureBoxes!.findIndex(pos => pos.signatureBoxId == signatureBoxId);
		setScrollState(Date.now());
		setCurrentSignIndex(signedIndex);
		setIsLoading(true);
		const sigPng = await getSignaturePng(signatureBoxId);
		createSignature(
			sigPng,
			envelopeId,
			signerId,
			documentId,
			signatureBoxId
		).then(() => refreshDocumentData())
	}

	const getSignatureSpots = (page: number) => {
		if (doc && pageSizes && pageSizes[page - 1]) {
			if (!signatureBoxes) return null;
			return (<>
				{
					signatureBoxes.map((sig, index) => {
						return (
							<SignatureBox
								zoom={zoom}
								sig={sig}
								page={page}
								signSignatureBox={signSignatureBox}
								key={index}
							/>)
					})
				}
			</>)
		} else {
			console.log('Missing Page sizes', pageSizes);
		}
	};

	const pdfDocument = useMemo(() => {
		if (!displayFile) {
			return null;
		} else {
			return (
				<>
					{
						numPages ? range(numPages).map((index) => {
							//@ts-ignore
							const pageSize = pageSizes[index];
							return (<React.Fragment key={index}>
								<div
									key={index}
									className={style.pageBox}
									style={{
										position: 'relative',
										width: pageSize.width * zoom,
										height: pageSize.height * zoom
									}}
								>
									<Document
										file={displayFile}
										renderMode={'svg'}
										className={'svg-doc'}
									>
										<Page
											pageNumber={index + 1}
											canvasRef={docCanvasRef}
											className={'svg-page'}
										/>
									</Document>

									{getSignatureSpots(index + 1)}
								</div>
								<div className={style.pageNumberBox}>
									{index + 1} / {numPages}
								</div>
							</React.Fragment>)
						}) : null
					}
				</>
			)
		}
	}, [displayFile, signatureBoxes, numPages, pageSizes, zoom]);

	const onSubmit = () => {
		if (!canSubmit) {
			return;
		}
		// todo finalize document if it is signed
		history.push(`/client/doc/${envelopeId}/${documentId}/${signerId}`);

	}

	return (<>
		<div className='container text-center'>
			<ZoomButtons/>
			<div style={{
				display: 'inline-block',
				position: 'relative'
			}}>
				{pdfDocument}
			</div>
		</div>


		<div className={style.bottomBar}>
			<div className='container-fluid'>
				<div className='row'>
					<div className='col'>
						Please review and execute the document.
					</div>
					<div className='col'>
						<div className={'row'}>
							<div className={'col'}>
								<Button
									onClick={handlePrevious}
									disabled={canSubmit}
								>
									Previous
								</Button>
							</div>
							<div className={'col text-right'}>
								<Button
									onClick={findAndSetNext}
									disabled={canSubmit}
								>
									Next
								</Button>
							</div>
						</div>
					</div>
					<div className='col text-right'>
						<button
							className='btn btn-success'
							disabled={!canSubmit}
							onClick={onSubmit}
						>
							Submit
						</button>
					</div>
				</div>
			</div>

		</div>
		<div className={style.bottomSpacer}></div>
		<div className={isLoading ? classnames(style.loadingBg, style.loadingBgActive) : classnames(style.loadingBg)}>
			<div className={style.loadingBox}>
				<AiOutlineLoading className='spin'/>
				<div className={style.loadingText}>
					LOADING
				</div>
			</div>
		</div>

	</>)
}

export default DocumentRender;