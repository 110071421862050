import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from "react-router-dom";
import {
	documentState,
	updateDocumentStates,
	signaturePickerState,
	signerState
} from '@state/documentStates';
import { useHistory } from 'react-router-dom';
import { getNumSignaturesLeft } from '@utils/doc';
import { updateSignerName } from '@api/client';
import style from './SignerPage.module.scss';
import { Redirect } from 'react-router-dom';
import sendIframeMessage from '@utils/sendIframeMessage';
import Loading from '@components/Loading';

import SignaturePicker from '@components/SignaturePicker';


type Params = {
	documentId: string,
	envelopeId: string,
	signerId: string
}

type Errors = {
	[field: string]: string
}

const DocumentPage = () => {
	const { documentId, envelopeId, signerId } = useParams() as Params;
	const [isLoading, setIsLoading] = useState(false);
	const [doc, setDoc] = documentState.use();
	const [signer, setSigner] = signerState.use();
	const [didAgree, setDidAgree] = useState(true);
	const history = useHistory();
	const [errors, setErrors] = useState({} as Errors);

	const updateErrors = () => {
		setErrors({});
	};

	// redirect if signer is done signing. 
	const numSignaturesLeft = useMemo(() => {
		if (doc && signer) {
			return getNumSignaturesLeft(doc, signer)
		} else {
			return 1;
		}
	}, [doc, signer]);

	useEffect(() => {
		console.log({
			documentId: documentId,
			envelopeId: envelopeId,
			signerId: signerId
		})
		setIsLoading(true);
		updateDocumentStates(
			envelopeId,
			documentId,
			signerId
		).then(() => {
			setIsLoading(false);
		})
		sendIframeMessage({
			stage: 1
		});
	}, []);

	const continueToDoc = async () => {
		let hasErrors = false;
		const e: Errors = {};
		const signaturePicker = signaturePickerState.get();

		// name
		if (signaturePicker.fullName.length < 3) {
			hasErrors = true;
			e['name'] = 'Please enter your full name';
		}

		// initials
		if (signaturePicker.initials.length < 2) {
			hasErrors = true;
			e['initials'] = 'Initials are 2 - 4 letters';
		}

		if (!didAgree) {
			hasErrors = true;
			e['agree'] = 'You must agree to these terms';
		}

		setErrors(e);

		if (!hasErrors) {
			// update name if needed
			if (signer?.fullName !== signaturePicker.fullName) {
				// name needs to be updated
				await updateSignerName(envelopeId, signerId, signaturePicker.fullName);
			}
			const newUrl = `/client/doc-sign/${envelopeId}/${documentId}/${signerId}`;
			history.push(newUrl);
		}
	}

	// redirect if signer is done
	if (!isLoading && numSignaturesLeft === 0) {
		return (
			<Redirect to={`/client/doc/${envelopeId}/${documentId}/${signerId}`} />
		)
	}

	if (isLoading) {
		return (
			<Loading />
		);
	}

	return (<>
		<div className='container' style={{ marginTop: 30 }}>
			<div className='card'>
				<div className='card-body'>
					<form
						onSubmit={(e) => {
							e.preventDefault();
							continueToDoc();
						}}
					>
						<h3>{isLoading ? 'Loading..' : doc ? doc?.filename : 'Not Found'}</h3>
						<SignaturePicker
							errors={errors}
							updateErrors={updateErrors}
						/>
						<div className={style.agreeBox}>
							<div className="form-group">
								<div className="form-check">
									<p>
										By continuing, I agree that the signature and initials will be the electronic representation of my signature and initials
										for all purposes when I (or my agent) use them on documents, including legally binding contracts - just
										the same as a pen-and-paper signature or initial.
									</p>
								</div>
							</div>
						</div>
						<div className={style.buttonBox}>
							<button
								type='submit'
								className='btn btn-primary'
							>
								Continue
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</>)
}

export default DocumentPage;