
const sendIframeMessage = (msg: any) => {
	try {
		if (window?.top?.postMessage) {
			window.top.postMessage(JSON.stringify(msg), '*');
		}
	} catch (e) {
		console.log(e)
	}
}

export default sendIframeMessage;