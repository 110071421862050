
export const objCopy = <T>(obj: T): T => {
	return JSON.parse(JSON.stringify(obj));
};

export const range = (num: number) => {
	let arr: number[] = [];
	for (let i = 0; i < num; i++) {
		arr.push(i);
	}
	return arr;
}

export const getInitials = (name: string) => {
	return name.split(' ').filter(x => x.length > 0).map(x => x[0]).join('').toUpperCase();
}