import React from 'react';
import { FaCircleNotch } from 'react-icons/fa';
import style from './Loading.module.scss';

const Loading = () => {
	return (
		<div className={style.box}>
			<div className={style.iconBox}>
				<FaCircleNotch className='spin' />
			</div>
			<div className={style.textBox}>
				Loading
			</div>
		</div>
	);
}

export default Loading;