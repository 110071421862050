import SignatureBoxes from '@interfaces/SignatureBoxes';
import {getSignatureBox} from '@state/documentStates';
// import { Signer } from '@api/admin/signer';
import {DocClient, SignatureFieldClient, SignerClient} from '@interfaces/client';


export const getSignatureBoxes = (
	signer: SignerClient,
	doc: DocClient
): SignatureBoxes => {
	// get signatures
	let newSignatureBoxes: SignatureBoxes = [];
	//@ts-ignore
	const thisSignerTag = `\\s${signer?.signer + 1}\\`;
	//@ts-ignore
	for (let i = 0; i < doc?.signatureFields.length; i++) {
		const sigField = doc?.signatureFields[i];
		//@ts-ignore
		const signature = doc?.signatures.find(x => x.signatureField.id === sigField?.id);
		if (sigField && (sigField?.placeholder === thisSignerTag || signature)) {
			// create a signature with this placeholder
			newSignatureBoxes.push(getSignatureBox(
				sigField.id,
				parseFloat(sigField.x),
				parseFloat(sigField.y),
				sigField.page,
				sigField.id,
				signature ? signature.id : null
			))
		}
	}
	return newSignatureBoxes;
}

// returns all missing sigs if signer is null
export const getMissingSignatureFields = (
	doc: DocClient,
	signer: SignerClient | null = null
): SignatureFieldClient[] => {
	let missingSignatures: SignatureFieldClient[] = [];
	for (let i = 0; i < doc.signatureFields.length; i++) {
		const sigField = doc.signatureFields[i];
		console.log('sigField', sigField)
		// check if signature is complete
		const sig = doc.signatures.find(x => x.signatureField.id === sigField.id);
		if (!sig) {
			if (signer) {
				// only return if is for signer
				const signerPlaceholder = `\\s${signer.signer + 1}\\`
				console.log('signerPlaceholder ', {
					signerPlaceholder: signerPlaceholder,
					'sigField.placeholder': sigField.placeholder
				})
				if (signerPlaceholder === sigField.placeholder) {
					missingSignatures.push(sigField);
				}
			} else {
				// return missing
				missingSignatures.push(sigField);
			}
		}
	}
	console.log('missingSignatures', missingSignatures)
	return missingSignatures;
}

export const getNumSignaturesLeft = (
	doc: DocClient,
	signer: SignerClient | null = null
) => {
	let missingSignatures = getMissingSignatureFields(doc, signer);
	return missingSignatures.length;
}