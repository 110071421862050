import fontLoader, { FontNames, fontScale } from './fontLoader';
import { signaturePickerState } from '@state/documentStates';

// adjusting this adjusts it everywhere on the frontend
export const signatureScale = 4;

export const signNameOnCanvas = (
	name: string,
	font: FontNames,
	canvas: HTMLCanvasElement,
	canvasTmp: HTMLCanvasElement,
	sigBoxId: string | null = null
) => {
	return new Promise(async (resolve, reject) => {
		// const padX = 15;
		const padX = Math.round(7.5 * signatureScale);
		// canvasTmp.width = 500;
		// canvasTmp.height = 80;

		canvasTmp.width = 250 * signatureScale;
		canvasTmp.height = 40 * signatureScale;

		const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
		const ctxTemp = canvasTmp.getContext('2d') as CanvasRenderingContext2D;
		ctx.save();
		ctxTemp.save();

		// load the font
		const f = await fontLoader.loadFont(font)
		ctxTemp.clearRect(0, 0, canvasTmp.width, canvasTmp.height);

		const fontSize = 20 * signatureScale;
		ctxTemp.font = `${Math.round(fontSize * fontScale[font])}px ${font}`;
		ctxTemp.fillStyle = "rgba(0, 0, 0, 0.6)";

		const metrics = ctxTemp.measureText(name);
		ctxTemp.fillText(
			name,
			padX,
			// (canvas.width / 2) - (metrics.width / 2),
			// 50
			25 * signatureScale
		);

		let metricWidth = metrics.width + (padX * 2);

		if (sigBoxId) {
			// fill in sigBoxId
			const idFont = 'RobotoMono-Regular';
			const idFontSize = 6 * signatureScale;
			//@ts-ignore
			const _ = await fontLoader.loadFont(idFont);
			ctxTemp.font = `${idFontSize}px ${idFont}`;
			ctxTemp.fillStyle = "rgba(0, 123, 192, 1)";

			const idMetrics = ctxTemp.measureText(sigBoxId.toUpperCase());
			metricWidth = Math.max(metricWidth, idMetrics.width + (padX * 2));
			ctxTemp.fillText(
				sigBoxId.toUpperCase(),
				padX,
				// (canvas.width / 2) - (metrics.width / 2),
				// 65
				Math.round(32.5 * signatureScale)
			);
		}

		// resize main canvas
		canvas.width = Math.min(metricWidth, canvasTmp.width);
		canvas.height = canvasTmp.height;

		ctx.clearRect(0, 0, canvas.width, canvas.height);
		ctx.drawImage(canvasTmp, 0, 0);
		ctx.restore();
		ctxTemp.restore();
		resolve(true);
	});
};

export const canvasToFile = (
	canvas: HTMLCanvasElement,
	fileName: string
): Promise<File> => {
	return new Promise((resolve, reject) => {
		canvas.toBlob((blob) => {
			if (blob) {
				const file = new File([blob], fileName);
				resolve(file);
			} else {
				reject('Could not make File')
			}
		}, 'image/png');
	});
}

export const getSignaturePng = (
	sigBoxId: string | null = null
): Promise<File> => {
	return new Promise((resolve) => {
		const canvas = document.createElement('canvas');
		const canvasTemp = document.createElement('canvas');
		const sig = signaturePickerState.get();
		signNameOnCanvas(
			sig.fullName,
			sig.fontName,
			canvas,
			canvasTemp,
			sigBoxId
		).then(() => {
			canvasToFile(canvas, 'signature.png').then((file) => {
				// remove canvases and return file
				canvas.remove();
				canvasTemp.remove();
				resolve(file);
			})
		})
	})
}

export const getInitialsPng = (
	sigBoxId: string | null = null
): Promise<File> => {
	return new Promise((resolve) => {
		const canvas = document.createElement('canvas');
		const canvasTemp = document.createElement('canvas');
		const sig = signaturePickerState.get();
		signNameOnCanvas(
			sig.initials,
			sig.fontName,
			canvas,
			canvasTemp,
			sigBoxId
		).then(() => {
			canvasToFile(canvas, 'initial.png').then((file) => {
				// remove canvases and return file
				canvas.remove();
				canvasTemp.remove();
				resolve(file);
			})
		})
	})
}
