import React from 'react';

import {
	documentState,
	signerState
} from '@state/documentStates';

import { useParams, Redirect } from 'react-router-dom';

type Props = {
	children: JSX.Element
}
type Params = {
	documentId: string,
	envelopeId: string,
	signerId: string
}

const RequireDocState = ({
	children
}: Props) => {
	const { documentId, envelopeId, signerId } = useParams() as Params;

	const signer = signerState.useValue();
	const document = documentState.useValue();

	if (!signer || !document) {
		// redirect to landing page for doc if missing items
		return (
			<Redirect to={`/client/sign/${envelopeId}/${documentId}/${signerId}`} />
		)
	} else {
		return (<>{children}</>)
	}

}

export default RequireDocState;